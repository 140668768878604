import { io } from "socket.io-client";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { Container } from "@mui/material";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useMemo } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import Homepage from "./pages/Homepage";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import LotDetail from "./pages/LotDetail";
import MyProfile from "./pages/MyProfile";

const socket = io(process.env.REACT_APP_BACKEND_URL);

function App() {
  const endpoint = process.env.REACT_APP_QUICK_NODE;
  const solNetwork = "mainnet-beta";
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter({ solNetwork }),
      new TorusWalletAdapter(),
      new LedgerWalletAdapter(),
    ],
    [solNetwork]
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets}>
        <WalletModalProvider>
          <ToastContainer />
          <Container disableGutters={true} maxWidth={false}>
            <Router>
              <Header socket={socket} />
              <Routes>
                <Route path={`/`} element={<Homepage />} />
                <Route path={`/profile`} element={<MyProfile />} />
                <Route
                  path={`/auction`}
                  element={<LotDetail socket={socket} />}
                />
              </Routes>
              <Footer />
            </Router>
          </Container>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default App;
