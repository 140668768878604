import axios from "axios";
import { toast } from "react-toastify";
import { useMemo, useState, useEffect } from "react";
import { Box, Grid, Modal, Typography } from "@mui/material";
import { useWallet } from "@solana/wallet-adapter-react";
import { Delete, Edit, Logout } from "@mui/icons-material";

import GetAvatar from "../tools/GetAvatar";
import GreyText from "../utils/texts/GreyText";
import WhiteText from "../utils/texts/WhiteText";
import GetShortName from "../tools/GetShortName";
import PurpleText from "../utils/texts/PurpleText";
import PurpleButton from "../utils/buttons/PurpleButton";
import GreyButton from "../utils/buttons/GreyButton";
import Input from "../utils/Input/Input";
import useStore from "../store";

const MyProfile = () => {
  const { disconnect, publicKey } = useWallet();
  const { userData, setUserData } = useStore();
  const { nftList } = useStore();

  const [openAvatarModel, setOpenAvatarModel] = useState(false);
  const [openNameModel, setOpenNameModel] = useState(false);
  const [openShippingModel, setOpenShippingModel] = useState(false);
  const [openEmailModel, setOpenEmailModel] = useState(false);
  const [changeAvatar, setChangeAvatar] = useState("");
  const [changeName, setChangeName] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [emailAddress, setEmailAddress] = useState("");

  useEffect(() => {
    setChangeAvatar(userData?.avatar);
  }, [userData]);

  const changeAvatarClicked = () => {
    setOpenAvatarModel(true);
  };
  const changeNameClicked = () => {
    setOpenNameModel(true);
  };
  const changeShippingAddressClicked = () => {
    setOpenShippingModel(true);
  };
  const changeEmailClicked = () => {
    setOpenEmailModel(true);
  };

  const nftComponent = useMemo(() => {
    return nftList?.map((nft, key) => {
      return (
        <Box
          component="img"
          src={nft.image}
          alt={key}
          width={100}
          height={100}
          key={key}
          onClick={() => setChangeAvatar(nft.image)}
        />
      );
    });
  }, [nftList, openAvatarModel]);

  const saveAvatar = async () => {
    const body = {
      walletAddress: userData?.walletAddress,
      changeAvatar: changeAvatar,
    };
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/users/changeAvatar`,
      body
    );
    if (res.data.status) {
      setUserData(res.data.content);
      setOpenAvatarModel(false);
      toast.success("Your avatar has changed!");
    } else {
      toast.error("Oops! Something went wrong. Please try again.");
    }
  };
  const saveName = async () => {
    const body = {
      walletAddress: userData?.walletAddress,
      changeName: changeName,
    };
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/users/changeName`,
      body
    );
    if (res.data.status) {
      setUserData(res.data.content);
      setOpenNameModel(false);
      toast.success("Your username has changed!");
    } else {
      toast.error("Oops! Something went wrong. Please try again.");
    }
  };
  const saveShippingAddress = async () => {
    const body = {
      walletAddress: userData?.walletAddress,
      shippingAddress: shippingAddress,
    };
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/users/shippingAddress`,
      body
    );
    if (res.data.status) {
      setUserData(res.data.content);
      setOpenShippingModel(false);
      toast.success("Your shipping address has changed!");
    } else {
      toast.error("Oops! Something went wrong. Please try again.");
    }
  };
  const saveEmailAddress = async () => {
    const body = {
      walletAddress: userData?.walletAddress,
      emailAddress: emailAddress,
    };
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/users/email`,
      body
    );
    if (res.data.status) {
      setUserData(res.data.content);
      setOpenEmailModel(false);
      toast.success("Your email address has changed!");
    } else {
      toast.error("Oops! Something went wrong. Please try again.");
    }
  };

  return (
    <Grid
      container
      sx={{
        background: "#141414",
        minHeight: "90vh",
      }}
    >
      <Grid item xs={0} sm={1} md={3} lg={4} />
      <Grid
        item
        xs={12}
        sm={10}
        md={6}
        lg={4}
        sx={{
          borderRadius: "0px 0px 64px 64px",
          width: "100%",
          padding: "20vh 0",
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            background: "rgba(255, 255, 255, 0.1)",
            borderRadius: "16px",
            padding: "20px",
          }}
        >
          <WhiteText>Profile Image:</WhiteText>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                width: "100px",
                height: "100px",
                background: "#262329",
                borderRadius: "50%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
              }}
            >
              <Box
                component="img"
                src={GetAvatar(userData?.avatar)}
                alt={GetShortName(userData?.userName)}
                width="50px"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Box>
                <GreyText fontSize={14}>
                  Select an avatar to personalize your account.
                </GreyText>
                <GreyText fontSize={14}>
                  You can choose your avatar an NFT
                </GreyText>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
                onClick={changeAvatarClicked}
              >
                <PurpleButton>Change avatar</PurpleButton>
                <Delete
                  sx={{
                    color: "grey",
                    padding: "10px",
                    border: "1px solid grey",
                    borderRadius: "50%",

                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            background: "rgba(255, 255, 255, 0.1)",
            borderRadius: "16px",
            padding: "20px",
          }}
        >
          <WhiteText>Email Address:</WhiteText>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <GreyText>{GetShortName(userData?.emailAddress)}</GreyText>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
              onClick={changeEmailClicked}
            >
              <Edit sx={{ color: "white" }} />
              <PurpleText>Edit</PurpleText>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
              background: "rgba(255, 255, 255, 0.1)",
              borderRadius: "16px",
              padding: "20px",
            }}
          >
            <WhiteText>Username:</WhiteText>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <GreyText>{GetShortName(userData?.userName)}</GreyText>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
                onClick={changeNameClicked}
              >
                <Edit sx={{ color: "white" }} />
                <PurpleText>Edit</PurpleText>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
              background: "rgba(255, 255, 255, 0.1)",
              borderRadius: "16px",
              padding: "20px",
            }}
          >
            <WhiteText>Shipping Address:</WhiteText>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <Typography
                color="grey"
                sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
              >
                {GetShortName(userData?.shippingAddress)}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
                onClick={changeShippingAddressClicked}
              >
                <Edit sx={{ color: "white" }} />
                <PurpleText>Edit</PurpleText>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={0} sm={1} md={3} lg={4} />
      <Modal
        open={openAvatarModel}
        onClose={() => setOpenAvatarModel(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            width: "350px",
            top: "50%",
            left: "50%",
            flexDirection: "column",
            justifyContent: "center",
            transform: "translate(-50%, -50%)",
            background: "#1F1F1F",
            borderRadius: "24px",
            padding: "20px",
          }}
        >
          <Typography
            fontSize={20}
            sx={{
              color: "white",
              borderBottom: "1px solid #333333",
              paddingBottom: "10px",
            }}
          >
            Change avatar
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            gap="10px"
            margin="20px"
            alignItems="center"
          >
            <WhiteText>Your avatar:</WhiteText>
            <Box
              sx={{
                background: "#262329",
                borderRadius: "100%",
                width: "100px",
                height: "100px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                component="img"
                src={GetAvatar(changeAvatar)}
                alt={GetShortName(userData?.userName)}
                width={35}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap="10px">
            <WhiteText>NFT in the connected wallet:</WhiteText>
            <Box
              sx={{
                height: "300px",
                overflowY: "scroll",
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                justifyContent: "space-around",
              }}
            >
              {nftComponent}
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" gap="10px">
            <GreyButton onClick={() => setOpenAvatarModel(false)} width="100%">
              Cancel
            </GreyButton>
            <PurpleButton onClick={saveAvatar} width="100%">
              Save
            </PurpleButton>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openNameModel}
        onClose={() => setOpenNameModel(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "#333333",
            padding: "24px",
            borderRadius: "20px",
            width: "350px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography
            fontSize={20}
            sx={{
              color: "white",
              paddingBottom: "10px",
              borderBottom: "1px solid grey",
            }}
          >
            Edit User Name
          </Typography>
          <Box>
            <WhiteText>Current User Name:</WhiteText>
            <GreyText fontSize={14}>{userData?.userName}</GreyText>
          </Box>
          <Box>
            <WhiteText>New User Name:</WhiteText>
            <Input
              value={changeName}
              onChange={(e) => setChangeName(e.target.value)}
              placeHolder="Enter new name"
            />
          </Box>
          <Box display="flex" justifyContent="space-between" gap="10px">
            <GreyButton onClick={() => setOpenNameModel(false)} width="100%">
              Cancel
            </GreyButton>
            <PurpleButton onClick={saveName} width="100%">
              Save
            </PurpleButton>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openShippingModel}
        onClose={() => setOpenShippingModel(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "#333333",
            padding: "24px",
            borderRadius: "20px",
            width: "350px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography
            fontSize={20}
            sx={{
              color: "white",
              paddingBottom: "10px",
              borderBottom: "1px solid grey",
            }}
          >
            Edit Shipping Address
          </Typography>
          <Box>
            <WhiteText>Current Shipping Address:</WhiteText>
            <GreyText fontSize={14}>{userData?.shippingAddress}</GreyText>
          </Box>
          <Box>
            <WhiteText>New Shipping Address:</WhiteText>
            <Input
              value={shippingAddress}
              onChange={(e) => setShippingAddress(e.target.value)}
              placeHolder="Enter new shipping address"
            />
          </Box>
          <Box display="flex" justifyContent="space-between" gap="10px">
            <GreyButton
              onClick={() => setOpenShippingModel(false)}
              width="100%"
            >
              Cancel
            </GreyButton>
            <PurpleButton onClick={saveShippingAddress} width="100%">
              Save
            </PurpleButton>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openEmailModel}
        onClose={() => setOpenEmailModel(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "#333333",
            padding: "24px",
            borderRadius: "20px",
            width: "350px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography
            fontSize={20}
            sx={{
              color: "white",
              paddingBottom: "10px",
              borderBottom: "1px solid grey",
            }}
          >
            Edit Email Address
          </Typography>
          <Box>
            <WhiteText>Current Email Address:</WhiteText>
            <GreyText fontSize={14}>{userData?.email}</GreyText>
          </Box>
          <Box>
            <WhiteText>New Email Address:</WhiteText>
            <Input
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
              placeHolder="Enter new email address"
            />
          </Box>
          <Box display="flex" justifyContent="space-between" gap="10px">
            <GreyButton onClick={() => setOpenEmailModel(false)} width="100%">
              Cancel
            </GreyButton>
            <PurpleButton onClick={saveEmailAddress} width="100%">
              Save
            </PurpleButton>
          </Box>
        </Box>
      </Modal>
    </Grid>
  );
};

export default MyProfile;
