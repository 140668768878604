import { Typography } from "@mui/material";

const YellowText = ({ children, fontSize, fontWeight, opacity, width, className }) => {
  return (
    <Typography
      color="#FBBC05"
      sx={{
        fontSize: fontSize ? fontSize : 16,
        fontWeight: fontWeight ? fontWeight : 400,
        opacity: opacity ? opacity : 1,
        width: width ? width : "auto"
      }}
      className={className? className: null}
    >
      {children}
    </Typography>
  )
}

export default YellowText;