import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";

import discordImg from "../../assets/imgs/discord.png"
import twitterImg from "../../assets/imgs/twitter.png"
import faceBookImg from "../../assets/imgs/facebook.png"
import WhiteText from "../../utils/texts/WhiteText";
import Avatar from "../../utils/imgs/Avatar/Avatar";
import GreyText from "../../utils/texts/GreyText";
import Input from "../../utils/Input/Input";

import "./Footer.scss"
import PurpleButton from "../../utils/buttons/PurpleButton";
import { ArrowForward, ArrowForwardIos } from "@mui/icons-material";

const Footer = () => {

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Grid container className="footer">
      <Grid item xs={0} sm={1} md={1} lg={1} />
      <Grid item xs={12} sm={10} md={10} lg={10} className="main">
        <Box className="container" style={{ flexDirection: isDesktop ? "row" : "column" }}>
          <Box className="ours">
            <Box className="logo">
              <WhiteText fontSize={24}>SOLBID</WhiteText>
            </Box>
            <Box className="links">
              <Avatar src={discordImg} alt="DISCORD" width={25} height={25} />
              <Avatar src={twitterImg} alt="TWITTER" width={25} height={25} />
              <Avatar src={faceBookImg} alt="FACEBOOK" width={25} height={25} />
            </Box>
          </Box>
          <Box className="infoContainer" style={{ flexDirection: isDesktop ? "row" : "column-reverse" }}>
            <Box className="infos">
              <Box className="info">
                <Box className="title">
                  <WhiteText fontWeight={700}>Help</WhiteText>
                </Box>
                <Box className="body">
                  <GreyText fontSize={14}>How to Bid</GreyText>
                  <GreyText fontSize={14}>Turtorial Auction</GreyText>
                  <GreyText fontSize={14}>Payments</GreyText>
                  <GreyText fontSize={14}>FAQ</GreyText>
                </Box>
              </Box>
              <Box className="info">
                <Box className="title">
                  <WhiteText fontWeight={700}>About</WhiteText>
                </Box>
                <Box className="body">
                  <GreyText fontSize={14}>About auction</GreyText>
                  <GreyText fontSize={14}>About win</GreyText>
                  <GreyText fontSize={14}>About partners</GreyText>
                </Box>
              </Box>
            </Box>
            <Box className="contact">
              <GreyText fontSize={14}>Enter your email to get notified by enetthi for latest updates.</GreyText>
              <Box className="email">
                <Input placeHolder="Enter email" width={isDesktop ? "100%" : "100%"} onChange={() => console.log("email")}>
                  <Box className="send">
                    <ArrowForward />
                  </Box>
                </Input>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={0} sm={1} md={1} lg={1} />
    </Grid>
  )
}

export default Footer;
