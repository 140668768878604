import { useState } from "react"
import { Box } from "@mui/material"
import { Link } from "react-router-dom"
import { Lens } from "@mui/icons-material"

import GreyText from "../../texts/GreyText"
import WhiteText from "../../texts/WhiteText"
import PurpleButton from "../../buttons/PurpleButton"
import GetShortName from "../../../tools/GetShortName"
import "./NFT.scss"

const NFT = ({ src, name, status, remain, fp, width, height, onClick }) => {

  let color = "#09B725";

  if (status === "Soon") {
    color = "#1394CE"
  } else if (status === "Next") {
    color = "#EBD82E"
  }

  return (
    <Link to="/auction" className="NFT" onClick={onClick ?? null}>
      <Box className="top">
        <Box className="name">
          <GreyText fontSize={14}>{GetShortName(name)}</GreyText>
        </Box>
        <Box className="stats">
          <Lens style={{ color: color }} />
          <GreyText fontSize={14}>{status}</GreyText>
        </Box>
      </Box>
      <Box className="bottom">
        <Box className="buttonGroup">
          <PurpleButton width="100%">{status === "Live" ? "Place Bid" : "View Auction"}</PurpleButton>
        </Box>
      </Box>
      <Box component="img" borderRadius="20px" src={src} name={name} width={width ? width : "auto"} height={height ? height : "auto"} />
    </Link>
  )
}

export default NFT;