import { Typography } from "@mui/material";

const GreyText = ({ children, fontSize, fontWeight, opacity, className }) => {
  return (
    <Typography
    color="grey"
    sx={{
      fontSize: fontSize ? fontSize : 16,
      fontWeight: fontWeight ? fontWeight : 400,
      opacity: opacity ? opacity : 1
    }}
    className={className? className : null}
    >
      {children}
    </Typography>
  )
}

export default GreyText;