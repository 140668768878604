import { Grid, Typography } from "@mui/material";
import { useMemo } from "react";
import useStore from "../store";
import GetDate from "../tools/GetDate";
import NFT from "../utils/imgs/NFT/NFT";
import bgImg from "../assets/imgs/BG.png";

const Homepage = () => {
  const { lotList } = useStore();
  const { setSelectedNFT } = useStore();

  const lotComponent = useMemo(() => {
    const renderList = lotList
      .filter(lot => lot.status !== "End")
      .slice(0, 3);
    if (renderList.length) {
      return renderList.map((lot, key) => {
        return (
          <NFT src={lot.image} name={lot.name} status={lot.status} width="350px" height="400px" key={key} onClick={() => setSelectedNFT(key)} />
        )
      })
    } else {
      return (
        <Typography sx={{
          color: "#888888",
          width: "100%",
          textAlign: "center",
          fontSize: "14px"
        }}>No Lots yet.
        </Typography>
      )
    }
  }, [lotList])

  return (
    <Grid container
      sx={{
        flexWrap: "nowrap",
        minHeight: "90vh",
        backgroundImage: `url(${bgImg})`,
        backgroundSize: "cover",
        backgroundColor: "#0F051D",
      }}
    >
      <Grid item xs={1} sm={2} md={2} />
      <Grid item xs={10} sm={8} md={8}
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          alignItems: "stretch",
          margin: "auto",
          height: "100%",
          justifyContent: "space-around"
        }}
      >
        {lotComponent}
      </Grid>
      <Grid item xs={1} sm={2} md={2} />
    </Grid >
  )
}

export default Homepage;