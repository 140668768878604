import { Typography } from "@mui/material";

const PurpleText = ({ children, fontSize, fontWeight, opacity, width, className, onClick }) => {
  return (
    <Typography
    color="#9747FF"
    onClick={onClick ? onClick : null}
    sx={{
      fontSize: fontSize ? fontSize : 16,
      fontWeight: fontWeight ? fontWeight : 400,
      opacity: opacity ? opacity : 1,
      width: width ? width : "auto",
    }}
    className={className ? className : null}
    >
      {children}
    </Typography>
  )
}

export default PurpleText;