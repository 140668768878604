import create from "zustand";

const useStore = create((set) => ({
  bidAmount: 0,
  factor1: 0,
  factor2: 0,
  factor3: 0,
  factor4: 0,
  liveLots: [],
  loading: false,
  lotList: [],
  nftList: [],
  onlineUsers: 0,
  selectedNFT: 0,
  userData: {},
  setBidAmount: (val) => {
    set({ bidAmount: val });
  },
  setFactor1: (val) => {
    set({
      factor1: val,
    });
  },
  setFactor2: (val) => {
    set({
      factor2: val,
    });
  },
  setFactor3: (val) => {
    set({
      factor3: val,
    });
  },
  setFactor4: (val) => {
    set({
      factor4: val,
    });
  },
  setLiveLots: (val) => {
    set({
      liveLots: val,
    });
  },
  setLoading: (val) => {
    set({
      loading: val,
    });
  },
  setLotList: (val) => {
    set({ lotList: val });
  },
  setNFTList: (val) => {
    set({ nftList: val });
  },
  setOnlineUsers: (val) => {
    set({
      onlineUsers: val,
    });
  },
  setSelectedNFT: (val) => {
    set({
      selectedNFT: val,
    });
  },
  setUserData: (val) => {
    set({
      userData: val,
    });
  },
}));

export default useStore;
