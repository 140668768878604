import { Button } from "@mui/material"

import "./YellowButton.scss"
import WhiteText from "../texts/WhiteText";
import YellowText from "../texts/YellowText";

const YellowButton = ({ children, variant, outlined, width, onClick, disabled }) => {
  return (
    <Button
      className="yellowButton"
      disabled={disabled}
      onClick={onClick? onClick : null}
      variant={variant ? variant : "solid"}
      style={{
        background: outlined ? "#00000000" : (disabled ? "#FFFFFF1A" : "#FBBC05"),
        border: disabled ? "none" : "1px solid #FBBC05",
        width: width? width : "auto",
      }} >
      {!outlined ?
        <WhiteText fontWeight={700} width="100%">
          {children}
        </WhiteText> :
        <YellowText fontWeight={700} width="100%">
          {children}
        </YellowText>
      }
    </Button>
  )
}

export default YellowButton;