const GetDate = (timestamp) => {
  let day = Math.floor(timestamp / (1000 * 3600 * 24))
  let hour = Math.floor((timestamp % (1000 * 3600 * 24)) / (1000 * 3600))
  let mins = Math.floor((timestamp % (1000 * 3600)) / (1000 * 60))
  let secs = Math.floor((timestamp % (1000 * 60)) / (1000))

  if (isNaN(day)) {
    day = 0
    hour = 0
    mins = 0
    secs = 0
  }

  return `${day}d:${hour}h:${mins}m:${secs}s`
}

export default GetDate;