import { Box } from "@mui/material"

import "./Input.scss"

const Input = ({ value, placeHolder, children, width, type, onChange, onKeyDown, required, readOnly }) => {
  return (
    <Box className="input" width={width ? width : "auto"}>
      <input value={value ? value : ""} readOnly={readOnly ? readOnly : false} placeholder={placeHolder ? placeHolder : ""} type={type ? type : "text"} onChange={onChange ? onChange : null} onKeyDown={onKeyDown ? onKeyDown : null} required={required? required : false} />
      {children ? children : null}
    </Box>
  )
}

export default Input