import { Button } from "@mui/material"

import "./GreyButton.scss"
import WhiteText from "../texts/WhiteText";
import GreyText from "../texts/GreyText";

const GreyButton = ({ children, variant, outlined, width, onClick, disabled }) => {
  return (
    <Button
      className="greyButton"
      disabled={disabled}
      onClick={onClick? onClick : null}
      variant={variant ? variant : "solid"}
      style={{
        background: outlined ? "#00000000" : (disabled ? "#FFFFFF1A" : "#FFFFFF1A"),
        width: width? width : "auto",
      }} >
      {!outlined ?
        <WhiteText fontWeight={700} width="100%">
          {children}
        </WhiteText> :
        <GreyText fontWeight={700} width="100%">
          {children}
        </GreyText>
      }
    </Button>
  )
}

export default GreyButton;