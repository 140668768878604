import * as solanaWeb3 from "@solana/web3.js";
import { Grid, Box, Typography, Button } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import useStore from "../store";
import defaultLot from "../assets/imgs/defaultNFT.png";
import gradientRight from "../assets/imgs/gradient-right.png";
import gradientLeft from "../assets/imgs/gradient-left.png";
import GetDate from "../tools/GetDate";
import { PhantomWalletName } from "@solana/wallet-adapter-wallets";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import {
  TOKEN_PROGRAM_ID,
  createBurnCheckedInstruction,
  getAssociatedTokenAddress,
} from "@solana/spl-token";
import axios from "axios";
import {
  PublicKey,
  TransactionMessage,
  VersionedTransaction,
} from "@solana/web3.js";
import {
  ExpandLess,
  ExpandMore,
  InfoOutlined,
  Lens,
} from "@mui/icons-material";
import WhiteText from "../utils/texts/WhiteText";
import GreyText from "../utils/texts/GreyText";
import GetShortName from "../tools/GetShortName";
import Input from "../utils/Input/Input";
import PurpleButton from "../utils/buttons/PurpleButton";
import YellowButton from "../utils/buttons/YellowButton";
import { toast } from "react-toastify";
import GetNum from "../tools/GetNumber";

const LotDetail = ({ socket }) => {
  const { connection } = useConnection();
  const { connected, select, connect, publicKey, disconnect, signTransaction } =
    useWallet();

  const { liveLots, setLiveLots } = useStore();
  const { bidAmount, setBidAmount } = useStore();
  const { lotList, setLotList } = useStore();
  const { selectedNFT } = useStore();
  const { userData, setUserData } = useStore();
  const { factor1, factor2, factor3, factor4 } = useStore();
  const [openDescription, setOpenDescription] = useState(false);
  const [autoAuction, setAutoAuction] = useState("");
  const [autoBidAmount, setAutoBidAmount] = useState(0);
  const [showAutoBidOption, setShowAutoBidOption] = useState(false);
  const fetchNum = 5;

  useEffect(() => {
    select(PhantomWalletName);
  }, []);

  useEffect(() => {
    socket.emit("startTimer");
    socket.emit("getLots", fetchNum);
    socket.on("getLots", (data) => {
      setLotList(data.lots);
    });
  }, []);
  const getCurrentAuction = () => {
    const activeLots = [];
    lotList.filter((lot) => {
      if (lot.status !== "End") {
        activeLots.push(lot);
      }
    });
    setLiveLots(activeLots);
  };
  useEffect(() => {
    getCurrentAuction();
  }, [lotList]);

  useEffect(() => {
    if (publicKey) {
      getBidAmount();
    }
  }, [connected, publicKey]);

  const connectWallet = async () => {
    try {
      await connect();
    } catch (err) {
      toast.error("Oops! Something went wrong in connecting wallet.");
      console.log("Error in connecting phantom: ", err);
    }
  };

  const getBidAmount = async () => {
    const filters = [
      {
        dataSize: 165,
      },
      {
        memcmp: {
          offset: 32,
          bytes: publicKey.toBase58(),
        },
      },
      {
        memcmp: {
          offset: 0,
          bytes: process.env.REACT_APP_BID_ADDRESS,
        },
      },
    ];
    const accounts = await connection.getParsedProgramAccounts(
      TOKEN_PROGRAM_ID,
      { filters: filters }
    );
    accounts.forEach((account, i) => {
      const parsedAccountInfo = account.account.data;
      const mintAddress = parsedAccountInfo["parsed"]["info"]["mint"];
      const tokenBalance =
        parsedAccountInfo["parsed"]["info"]["tokenAmount"]["uiAmount"];
      setBidAmount(tokenBalance);
    });
  };

  const bidNow = async () => {
    if (userData?.bidAmount > 0 && liveLots[selectedNFT]?.status === "Live") {
      await bidOnLot();
      // burnBidToken()
      await signIn();
    }
  };

  const bidOnLot = async () => {
    const num = await GetNum(
      publicKey?.toBase58(),
      factor1,
      factor2,
      factor3,
      factor4
    );
    const body = {
      num: num,
      walletAddress: publicKey?.toBase58(),
      lotAddress: liveLots[selectedNFT]?.mintAddress,
    };
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/tools/bidNow`,
      body
    );
    if (res) {
      toast.success("Bid on lot succeed.");
    } else {
      toast.error("Oops! Something went wrong in bid on lot.");
    }
  };

  const signIn = async () => {
    try {
      let walletAddress = publicKey?.toBase58();
      const body = {
        walletAddress: walletAddress,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/users`,
        body
      );
      if (res.data.status) {
        setUserData(res.data.content);
      } else {
        console.log("Error in signup process");
      }
    } catch (err) {
      console.log("Error in signup", err);
    }
  };

  const burnBidToken = async () => {
    // const MINT_ADDRESS = process.env.REACT_APP_BID_ADDRESS;
    console.log("wallet", publicKey?.toBase58());
    const MINT_ADDRESS = "NUGTyaVimdx4bq9AJJd51mWXqmW4TDrhHUC32HAmZtY";
    const info = await connection.getParsedAccountInfo(
      new PublicKey(MINT_ADDRESS)
    );
    const MINT_DECIMALS = (info.value?.data).parsed.info.decimals;
    const BURN_QUANTITY = 1;
    const account = await getAssociatedTokenAddress(
      new PublicKey(MINT_ADDRESS),
      publicKey
    );
    const burnIx = createBurnCheckedInstruction(
      account, // PublicKey of Owner's Associated Token Account
      new PublicKey(MINT_ADDRESS), // Public Key of the Token Mint Address
      publicKey, // Public Key of Owner's Wallet
      BURN_QUANTITY * 10 ** MINT_DECIMALS, // Number of tokens to burn
      MINT_DECIMALS // Number of Decimals of the Token Mint
    );
    const { blockhash, lastValidBlockHeight } =
      await connection.getLatestBlockhash("finalized");
    const messageV0 = new TransactionMessage({
      payerKey: publicKey,
      recentBlockhash: blockhash,
      instructions: [burnIx],
    }).compileToV0Message();
    const transaction = new VersionedTransaction(messageV0);
    const signedTx = await signTransaction(transaction);
    // const t1 = solanaWeb3.Transaction.from(signedTx.serialize());
    // const t1 = solanaWeb3.VersionedMessage.deserialize(signedTx.serialize());

    // let stringfyTx = JSON.stringify(t1.serialize());
    const hash = await connection.sendRawTransaction(signedTx.serialize());
    let sig = null;
    while (sig == null) {
      sig = await connection.getParsedTransaction(hash, {
        commitment: "finalized",
        maxSupportedTransactionVersion: 0,
      });
    }

    const resu = await connection.getSignatureStatus(hash.toString(), {
      searchTransactionHistory: true,
    });
    if (resu.value?.status?.Err) {
      console.log(`===Burn Bids Failed===`);
      return false;
    } else {
      console.log("burn succeed");
      return true;
    }
  };

  const autoBidNow = async () => {
    if (parseInt(autoAuction) < 1) {
      toast.error("Invalid auction number");
      return;
    }
    if (parseInt(autoAuction) < parseInt(userData?.bidAmount)) {
      setShowAutoBidOption(true);
      setAutoAuction("");
      const num = await GetNum(
        publicKey?.toBase58(),
        factor1,
        factor2,
        factor3,
        factor4
      );
      const body = {
        walletAddress: publicKey?.toBase58(),
        lotAddress: liveLots[selectedNFT]?.mintAddress,
        num: num,
        bidAmount: autoAuction,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/tools/setAutoBid`,
        body
      );
      if (res.data.status) {
        toast.success("Auto bid succeed.");
        setUserData(res.data.content);
      } else {
        console.log("Error in auto bid", res.data.content);
        toast.error(res.data.content);
      }
    } else {
      toast.error("Insufficient bid balance");
    }
  };

  const removeBid = async () => {
    setShowAutoBidOption(false);
    setAutoAuction("");
    const num = await GetNum(
      publicKey.toBase58(),
      factor1,
      factor2,
      factor3,
      factor4
    );
    const body = {
      walletAddress: publicKey?.toBase58(),
      lotAddress: liveLots[selectedNFT]?.mintAddress,
      num: num,
    };
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/tools/removeAutoBid`,
      body
    );
    if (res.data.status) {
      toast.success("Remove auto bid succeed.");
      setUserData(res.data.content);
    } else {
      toast.error(res.data.content);
      console.log("Error in remove bid", res.data.content);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Live":
        return "green";
      case "End":
        return "red";
      case "Next":
        return "yellow";
      default:
        return "#1394CE";
    }
  };

  const bidderComponent = useMemo(() => {
    const bidders = liveLots[selectedNFT]?.bidders;
    return bidders?.map((bidder, key) => {
      return (
        <Box
          display="flex"
          justifyContent="space-between"
          key={key}
          sx={{
            background: "#443f46f0",
            padding: "10px 20px",
            width: "auto",
            borderRadius: "100px",
          }}
        >
          <Typography color="black">{bidder?.name}</Typography>
          <Typography color="black">{key + 1}</Typography>
        </Box>
      );
    });
  }, [lotList]);

  return (
    <Grid
      container
      sx={{
        flexWrap: "nowrap",
        minHeight: "100vh",
        background: "#0F051D",
        position: "relative",
      }}
    >
      <Box
        component="img"
        src={gradientLeft}
        alt="gradient"
        sx={{
          position: "absolute",
          top: "10%",
          left: "0%",
        }}
      />
      <Box
        component="img"
        src={gradientRight}
        alt="gradient"
        sx={{
          position: "absolute",
          bottom: "10%",
          right: "0%",
        }}
      />
      <Grid item xs={1} sm={2} md={2} />
      <Grid
        item
        xs={10}
        sm={8}
        md={8}
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          alignItems: "stretch",
          margin: "auto",
          height: "100%",
          justifyContent: "space-around",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Box
            component="img"
            src={liveLots[selectedNFT]?.image ?? defaultLot}
            width="500px"
            borderRadius="20px"
            border="3px solid #8c789761"
            alt="NFT"
          />
          <Box display="flex" flexDirection="column" gap="0">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              background="purple"
              borderRadius="12px"
              padding="10px 20px"
              border="1px solid #8c789761"
              onClick={() => setOpenDescription(!openDescription)}
              sx={{
                transition: "0.5s cubic-bezier(0.4, 0, 1, 1)",
                borderBottomLeftRadius: openDescription ? "0px" : "12px",
                borderBottomRightRadius: openDescription ? "0px" : "12px",
              }}
            >
              <Typography color="white" width="100%">
                Description
              </Typography>
              {openDescription ? (
                <ExpandLess sx={{ color: "white" }} />
              ) : (
                <ExpandMore sx={{ color: "white" }} />
              )}
            </Box>
            <Box
              sx={{
                background: "transparent",
                border: "1px solid #8c789761",
                height: openDescription ? "100%" : "0px",
                minHeight: openDescription ? "150px" : "0px",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                transition: "0.5s cubic-bezier(0.4, 0, 1, 1)",
                visibility: openDescription ? "show" : "hidden",
              }}
            >
              {liveLots[selectedNFT]?.desc ? (
                <WhiteText fontWeight={600}>
                  {liveLots[selectedNFT]?.desc}
                </WhiteText>
              ) : (
                <GreyText>No description here.</GreyText>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          gap="20px"
        >
          <Typography
            color="white"
            fontSize="24px"
            textAlign="center"
            fontWeight="600"
          >
            {liveLots[selectedNFT]?.name ?? "Default NFT"}
          </Typography>
          <Box
            sx={{
              height: "300px",
              overflowY: "auto",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: liveLots[selectedNFT]?.bidders.length
                ? "flex-start"
                : "center",
              borderTop: "1px solid grey",
              gap: "10px",
              padding: "10px 0px",
              borderBottom: "1px solid grey",

              "::-webkit-scrollbar": {
                width: "10px",
                height: "5px",
              },

              "::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 5px grey",
                borderRadius: "10px",
              },

              /* Handle */
              "::-webkit-scrollbar-thumb": {
                background: "rgb(133, 133, 133)",
                borderRadius: "10px",
              },

              /* Handle on hover */
              "::-webkit-scrollbar-thumb:hover": {
                background: "#535353",
              },
            }}
          >
            {liveLots[selectedNFT]?.bidders.length ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    background: "rgba(151, 71, 255, 0.1)",
                    borderRadius: "100px",
                    padding: "5px 10px",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      borderRadius: "100px",
                      padding: "2px 7px",
                      background: "rgba(0, 0, 0, 0.6)",
                      backdropFilter: "blur(2px)",
                    }}
                  >
                    <Lens
                      sx={{
                        color: getStatusColor(liveLots[selectedNFT]?.status),
                        fontSize: "12px",
                      }}
                    />
                    <GreyText fontSize={14}>
                      {liveLots[selectedNFT]?.status}
                    </GreyText>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <WhiteText fontSize={14}>Total bids:</WhiteText>
                    <WhiteText fontSize={20}>
                      #{liveLots[selectedNFT]?.bidders.length}
                    </WhiteText>
                  </Box>
                </Box>
                {bidderComponent}
              </>
            ) : (
              <Typography color="#888888" textAlign="center">
                No bidders yet.
              </Typography>
            )}
          </Box>
          <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            sx={{
              background: "#ffffff1a",
              padding: "10px 20px",
              width: "auto",
              borderRadius: "100px",
            }}
          >
            <Typography color="#888888">Current Winner:</Typography>
            <Typography color="white">
              {GetShortName(liveLots[selectedNFT]?.bidders[0]?.name ?? "-")}
            </Typography>
          </Box>
          <Box>
            <Typography color="#ffffff8a" textAlign="center">
              Auction ends in:
            </Typography>
            <Typography color="#EB4335" textAlign="center">
              {GetDate(liveLots[selectedNFT]?.time ?? 0)}
            </Typography>
            <Typography color="white" textAlign="center">
              Connect your wallet and add $BID to bid in the auction
            </Typography>
          </Box>
          {connected ? (
            liveLots[selectedNFT]?.status === "Live" ? (
              <Box display="flex" flexDirection="column" gap="20px">
                <Button
                  sx={{
                    background: "#680BDE",
                    color: "white",
                    borderRadius: "100px",
                    "&:hover": {
                      background: "#752bd1",
                    },
                  }}
                  onClick={bidNow}
                >
                  Bid
                </Button>
                <Box
                  sx={{
                    background: "rgba(255, 255, 255, 0.1)",
                    borderRadius: "16px",
                    padding: "16px 0",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "5px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <GreyText fontSize={20}>Auto-bid</GreyText>
                    <InfoOutlined color="white" sx={{ color: "grey" }} />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginInline: "15px",
                      gap: "10px",
                      justifyContent: "space-between",
                    }}
                  >
                    {autoBidAmount ? (
                      <WhiteText fontSize={14}>{autoBidAmount}</WhiteText>
                    ) : null}
                    <Input
                      placeHolder="Enter bids"
                      width="100%"
                      value={autoAuction}
                      type="number"
                      onChange={(e) => setAutoAuction(e.target.value)}
                    />
                    {showAutoBidOption ? (
                      <Box display="flex" gap="5px" width="100%">
                        <PurpleButton width="100%" onClick={autoBidNow}>
                          Add
                        </PurpleButton>
                        <YellowButton width="100%" onClick={removeBid}>
                          Remove
                        </YellowButton>
                      </Box>
                    ) : (
                      <PurpleButton onClick={autoBidNow} width="100%">
                        Set auto bid
                      </PurpleButton>
                    )}
                  </Box>
                </Box>
              </Box>
            ) : (
              <Button
                sx={{
                  background: "#680BDE",
                  color: "#888888!important",
                  borderRadius: "100px",
                  "&:hover": {
                    background: "#752bd1",
                  },
                }}
                disabled
                onClick={bidNow}
              >
                Not started yet...
              </Button>
            )
          ) : (
            <Button
              sx={{
                background: "#680BDE",
                color: "white",
                borderRadius: "100px",
                "&:hover": {
                  background: "#752bd1",
                },
              }}
              onClick={connectWallet}
            >
              Connect Button
            </Button>
          )}
        </Box>
      </Grid>
      <Grid item xs={1} sm={2} md={2} />
    </Grid>
  );
};

export default LotDetail;
