import { Button } from "@mui/material";

import "./PurpleButton.scss";
import WhiteText from "../texts/WhiteText";
import PurpleText from "../texts/PurpleText";

const PurpleButton = ({
  children,
  variant,
  outlined,
  width,
  onClick,
  disabled,
}) => {
  return (
    <Button
      className="purpleButton"
      disabled={disabled}
      onClick={onClick ? onClick : null}
      variant={variant ? variant : "solid"}
      style={{
        background: outlined
          ? "#00000000"
          : disabled
          ? "#FFFFFF1A"
          : "linear-gradient(60.04deg, #2600FC 0%, #FF00EA 100%)",
        // border: disabled ? "none" : "1px solid #680BDE",
        width: width ? width : "auto",
      }}
    >
      {!outlined ? (
        <WhiteText fontWeight={700} width="100%">
          {children}
        </WhiteText>
      ) : (
        <PurpleText fontWeight={700} width="100%">
          {children}
        </PurpleText>
      )}
    </Button>
  );
};

export default PurpleButton;
