import axios from "axios";
import { toast } from "react-toastify";
import * as solanaWeb3 from "@solana/web3.js";
import { Link, useNavigate } from "react-router-dom/dist";
import {
  createAssociatedTokenAccountInstruction,
  createTransferInstruction,
  getAssociatedTokenAddress,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import { useEffect, useMemo, useCallback, useState } from "react";
import { PhantomWalletName } from "@solana/wallet-adapter-phantom";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { Box, Grid, Modal, useMediaQuery, useTheme } from "@mui/material";
import {
  Close,
  ExpandMore,
  Lens,
  Menu,
  Settings,
  Wallet,
} from "@mui/icons-material";

import useStore from "../../store";
import GetNum from "../../tools/GetNumber";
import GetAvatar from "../../tools/GetAvatar";
import GreyText from "../../utils/texts/GreyText";
import WhiteText from "../../utils/texts/WhiteText";
import PurpleButton from "../../utils/buttons/PurpleButton";
import logoImg from "../../assets/imgs/logo.png";

import "./Header.scss";

const Header = ({ socket }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { connection } = useConnection();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const { publicKey, connected, select, connect, signTransaction } =
    useWallet();
  const { bidAmount, setBidAmount } = useStore();
  const { setLoading } = useStore();
  const { userData, setUserData } = useStore();
  const { factor1, factor2, factor3, factor4 } = useStore();
  const { setFactor1, setFactor2, setFactor3, setFactor4 } = useStore();
  const { setLiveLots } = useStore();
  const { lotList, setLotList } = useStore();
  const { setNFTList } = useStore();
  const { onlineUsers, setOnlineUsers } = useStore();

  const [depositAmount, setDepositAmount] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [openDepositModal, setOpenDepositModal] = useState(false);
  const [openMobileSetting, setOpenMobileSetting] = useState(false);
  const [depositOption, setDepositOption] = useState(true);
  const fetchNum = 5;

  useEffect(() => {
    socket.emit("startTimer");
    socket.emit("getLots", fetchNum);
    socket.on("getLots", (data) => {
      setLotList(data.lots);
    });
    socket.on("onlineUser", (data) => {
      setOnlineUsers(data);
    });
    getLots();
    select(PhantomWalletName);
  }, []);

  useEffect(() => {
    getCurrentAuction();
  }, [lotList]);

  useEffect(() => {
    if (publicKey) {
      getBidAmount();
      getAllNftData();
      signIn();
    }
  }, [connected, publicKey]);

  const getLots = useCallback(async () => {
    setLoading(true);
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/lots`
    );
    if (res.data.status) {
      setFactor1(res.data.content.data1);
      setFactor2(res.data.content.data2);
      setFactor3(res.data.content.data3);
      setFactor4(res.data.content.data4);
    }
    setLoading(false);
  }, []);

  const getCurrentAuction = () => {
    const activeLots = [];
    lotList.filter((lot) => {
      if (lot.status !== "End") {
        activeLots.push(lot);
      }
    });
    setLiveLots(activeLots);
  };

  const getBidAmount = async () => {
    const filters = [
      {
        dataSize: 165,
      },
      {
        memcmp: {
          offset: 32,
          bytes: publicKey.toBase58(),
        },
      },
      {
        memcmp: {
          offset: 0,
          bytes: process.env.REACT_APP_BID_ADDRESS,
        },
      },
    ];
    const accounts = await connection.getParsedProgramAccounts(
      TOKEN_PROGRAM_ID,
      { filters: filters }
    );
    accounts.forEach((account, i) => {
      const parsedAccountInfo = account.account.data;
      const mintAddress = parsedAccountInfo["parsed"]["info"]["mint"];
      const tokenBalance =
        parsedAccountInfo["parsed"]["info"]["tokenAmount"]["uiAmount"];
      setBidAmount(tokenBalance);
    });
  };

  const getAllNftData = async () => {
    try {
      const options = {
        method: "GET",
        headers: { accept: "application/json" },
      };
      const result = await axios.get(
        `${
          process.env.REACT_APP_MAGIC_EDEN_API
        }/wallets/${publicKey?.toBase58()}/tokens`,
        options
      );
      setNFTList(result.data);
    } catch (error) {
      console.log("Error while getting nft data.", error);
    }
  };

  const signIn = async () => {
    try {
      let walletAddress = publicKey?.toBase58();
      const body = {
        walletAddress: walletAddress,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/users`,
        body
      );
      if (res.data.status) {
        setUserData(res.data.content);
      } else {
        toast.error("Oops! something went wrong in singin process.");
      }
    } catch (err) {
      toast.error("Oops! something went wrong in singin process.");
      console.log("Error in signup", err);
    }
  };

  const connectWallet = async () => {
    try {
      await connect();
    } catch (err) {
      toast.error("Oops! something went wrong in connecting wallet.");
      console.log("Error in connecting phantom: ", err);
    }
  };

  const deposit = async () => {
    let transaction = new solanaWeb3.Transaction();
    if (parseInt(bidAmount) < parseInt(depositAmount)) {
      toast.error("You don't have enough bid amount");
      return;
    } else {
      const mintPublicKey = new solanaWeb3.PublicKey(
        process.env.REACT_APP_BID_ADDRESS
      );
      const ownerPublicKey = publicKey;
      const destPublicKey = new solanaWeb3.PublicKey(
        process.env.REACT_APP_HOUSE_ADDR
      );

      const info = await connection.getParsedAccountInfo(mintPublicKey);
      const decimal = (info.value?.data).parsed.info.decimals;

      // GET SOURCE ASSOCIATED ACCOUNT
      const associatedSourceTokenAddr = await getAssociatedTokenAddress(
        mintPublicKey,
        ownerPublicKey
      );

      // GET DESTINATION ASSOCIATED ACCOUNT
      const associatedDestinationTokenAddr = await getAssociatedTokenAddress(
        mintPublicKey,
        destPublicKey
      );

      const receiverAccount = await connection.getAccountInfo(
        associatedDestinationTokenAddr
      );

      const instructions = [];

      if (receiverAccount === null) {
        instructions.push(
          createAssociatedTokenAccountInstruction(
            mintPublicKey,
            associatedDestinationTokenAddr,
            destPublicKey,
            ownerPublicKey
          )
        );
      }
      instructions.push(
        createTransferInstruction(
          associatedSourceTokenAddr,
          associatedDestinationTokenAddr,
          ownerPublicKey,
          depositAmount * Math.pow(10, decimal),
          []
        )
      );
      for (let i = 0; i < instructions.length; i++) {
        transaction.add(instructions[i]);
      }

      let signedTx;
      transaction.recentBlockhash = (
        await connection.getLatestBlockhash("max")
      ).blockhash;
      transaction.feePayer = publicKey;
      await signTransaction(transaction)
        .then((res) => {
          signedTx = res;
        })
        .catch((err) => {
          toast.error("Transaction not approved.");
          return false;
        });
      const t1 = solanaWeb3.Transaction.from(signedTx.serialize());
      let stringfyTx = JSON.stringify(t1.serialize());

      const num = await GetNum(
        publicKey?.toBase58(),
        factor1,
        factor2,
        factor3,
        factor4
      );
      if (num) {
        setOpenDepositModal(false);
        setLoading(true);
        const body = {
          walletAddress: publicKey?.toBase58(),
          signedTx: stringfyTx,
          amount: depositAmount,
          num: num,
        };
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/tools/deposit`,
          body
        );
        if (res.data.status) {
          toast.success("Deposit BID succeed");
        } else {
          console.log("Error in deposit BID", res.data.content);
          toast.error("Error in deposit BID");
        }
        getBidAmount();
        signIn();
      } else {
        toast.error("Oops! Something went wrong. Please try again.");
      }
      setDepositAmount("");
      setLoading(false);
    }
  };

  const withdraw = async () => {
    try {
      const num = await GetNum(
        publicKey?.toBase58(),
        factor1,
        factor2,
        factor3,
        factor4
      );
      if (parseInt(userData?.bidAmount) >= parseInt(withdrawAmount)) {
        setOpenDepositModal(false);
        setLoading(true);
        const body = {
          walletAddress: publicKey?.toBase58(),
          amount: withdrawAmount,
          num: num,
        };
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/tools/withdraw`,
          body
        );
        if (res.data.status) {
          toast.success("Hooray! Withdraw succeed.");
        } else {
          toast.error("Oops! Something went wrong and withdraw failed");
        }
        setLoading(false);
        setDepositAmount("");
        signIn();
        getBidAmount();
      } else {
        toast.error("Oops! You don't have enough BID in your account");
      }
    } catch (err) {
      toast.error("Oops! Something went wrong and withdraw failed");
      console.log("Error in withdraw", err);
    }
  };

  const avatarComponent = useMemo(() => {
    return <img src={GetAvatar(userData?.avatar)} alt="User" />;
  }, [userData]);

  return (
    <Grid container className="header">
      <Box className="group">
        <Box onClick={() => navigate(`/`)} className="logo">
          <Box
            component="img"
            src={logoImg}
            alt="LOGO"
            width="50px"
            borderRadius="100%"
          />
          <WhiteText fontSize={24}>SOLBID</WhiteText>
        </Box>
        {isDesktop ? (
          <Box className="navs">
            <Box onClick={() => navigate(`/auction`)}>
              <WhiteText fontSize={16}>Auctions</WhiteText>
            </Box>
          </Box>
        ) : null}
      </Box>
      <Box className="group" style={{ gap: isDesktop ? "25px" : "0px" }}>
        {/* {connected ?
          <Box className="bidState">
            <WhiteText fontSize={14}>{userData?.bidAmount} bids</WhiteText>
          </Box> : null
        } */}
        {isDesktop ? (
          <Box className="onlineUser">
            <Box className="num">
              <Lens />
              <WhiteText fontSize={14}>{onlineUsers}</WhiteText>
            </Box>
            <GreyText className="label" fontSize={14}>
              Users online
            </GreyText>
          </Box>
        ) : null}
        {connected ? (
          <>
            {isDesktop ? (
              <Box className="profile">
                <Box className="user">{avatarComponent}</Box>
                <ExpandMore />
                <Box className="options">
                  <Link to="/profile" className="option">
                    <Settings color="white" />
                    <WhiteText>Settings</WhiteText>
                  </Link>
                  <Box
                    className="option"
                    onClick={() => setOpenDepositModal(true)}
                  >
                    <Wallet color="white" />
                    <WhiteText>Wallet</WhiteText>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box className="profile">
                {openMobileSetting ? (
                  <Close
                    style={{ fontSize: 35 }}
                    onClick={() => setOpenMobileSetting(false)}
                  />
                ) : (
                  <Menu
                    style={{ fontSize: 35 }}
                    onClick={() => setOpenMobileSetting(true)}
                  />
                )}
              </Box>
            )}
          </>
        ) : (
          <Box className="wallet">
            <PurpleButton onClick={connectWallet}>Connect Wallet</PurpleButton>
          </Box>
        )}
      </Box>
      <Modal
        open={openDepositModal}
        onClose={() => setOpenDepositModal(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box className="depositModal">
          <Box className="options">
            <Box
              className={depositOption ? "option selected" : "option"}
              onClick={() => setDepositOption(true)}
            >
              <WhiteText>Deposit</WhiteText>
            </Box>
            <Box
              className={!depositOption ? "option selected" : "option"}
              onClick={() => setDepositOption(false)}
            >
              <WhiteText>Withdraw</WhiteText>
            </Box>
          </Box>
          {depositOption ? (
            <Box className="inputs">
              <GreyText fontSize={14}>Deposit $BID</GreyText>
              <Box
                component="input"
                type="number"
                value={depositAmount}
                onChange={(e) => setDepositAmount(e.target.value)}
                placeHolder="Please enter deposit amount..."
                sx={{
                  background: "rgba(255, 255, 255, 0.1)",
                  border: "1px solid rgba(255, 255, 255, 0.2)",
                  borderRadius: "90px",
                  padding: "10px",
                  width: "auto",
                  color: "white",
                }}
              />
              <PurpleButton onClick={deposit}>DEPOSIT</PurpleButton>
            </Box>
          ) : (
            <Box className="inputs">
              <GreyText fontSize={14}>Withdraw $BID</GreyText>
              <Box
                component="input"
                type="number"
                value={withdrawAmount}
                onChange={(e) => setWithdrawAmount(e.target.value)}
                placeHolder="Please enter withdraw amount..."
                sx={{
                  background: "rgba(255, 255, 255, 0.1)",
                  border: "1px solid rgba(255, 255, 255, 0.2)",
                  borderRadius: "90px",
                  padding: "10px",
                  width: "auto",
                  color: "white",
                }}
              />
              <PurpleButton onClick={withdraw}>WITHDRAW</PurpleButton>
            </Box>
          )}
        </Box>
      </Modal>
    </Grid>
  );
};

export default Header;
