import { Box } from "@mui/material";

import "./Avatar.scss"

const Avatar = ({ src, alt, width, height }) => {
  return (
    <Box className="avatar" style={{width: width? width : 35, height: height? height : 35}}>
      <img src={src} alt={alt} style={{width: width? width: 35, height: height? height: 35}} />
    </Box>
  )
}

export default Avatar;